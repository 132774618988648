import React from "react";
import {
  StyledWhyToJoinTitle,
  HowToJoinWrapper,
  WrapperContent,
  WrapperItem,
  StyledParagraph,
  StyledLabel,
  StyledText,
  StyledNumber,
} from "./style.jsx";
import {
  StyledBeeCta,
  StyledWrapperButton,
} from "../BeeWho/style";

export default ({ content }) => {

  return (
    <HowToJoinWrapper >
      <StyledWhyToJoinTitle
        dangerouslySetInnerHTML={{ __html: content.title }}
      />
      <WrapperContent>
        {content.containsList?.map((e, id) => (
          <WrapperItem key={e.id}>
            <StyledNumber dangerouslySetInnerHTML={{ __html: String((id + 1)) }} />
            <StyledParagraph>
              <StyledLabel dangerouslySetInnerHTML={{ __html: e.title }} />
              <StyledText dangerouslySetInnerHTML={{ __html: e.text }} />
            </StyledParagraph>
          </WrapperItem>
        ))}
      </WrapperContent>
      <StyledWrapperButton >
        <StyledBeeCta >
          <a href="#contactForm">{content.ctaLabel}</a>
        </StyledBeeCta>
      </StyledWrapperButton>
    </HowToJoinWrapper>
  );
};
