import styled from "styled-components";
import {
  Rem,
  BP,
  Colors,
} from "../../../commons/Theme";

export const HowToJoinWrapper = styled.div`
  padding: ${Rem(30)} ${Rem(25)};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 93rem;
  @media (${BP.tablet}) {
    max-width:1248px;
    margin: 0 auto;
    gap: 3rem;
    padding: ${Rem(100)} 2rem;
  }
`;

export const StyledWhyToJoinTitle = styled.h1`
  text-align: left;
  font-size: ${Rem(32)};
  padding: 0;
  padding-bottom: 2rem;
  @media (${BP.tablet}) {
    font-size: ${Rem(40)};
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  padding: 2rem 0;
  @media (${BP.tablet}) {
    padding: 0;
    flex-direction: row;
    gap: 7rem;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  @media (${BP.tablet}) {
    gap: 1.5rem;
  }
`;

export const StyledNumber = styled.div`
  color: ${Colors.yellow};
  font-size: ${Rem(64)};
  min-width: 38px;
  line-height: 3rem;
  @media (${BP.tablet}) {
    min-width: unset;
  }
`;

export const StyledParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledLabel = styled.h3`
  font-size: ${Rem(18)};
  font-weight: 600; 
  text-align: left;
  @media (${BP.tablet}) {
    font-size: ${Rem(24)};
  }
`;

export const StyledText = styled.p`
  font-size: ${Rem(14)};
  font-weight: 300;
  text-align: left;
  @media (${BP.tablet}) {
    font-size: ${Rem(18)};
  }
`;
